.table-wrapper
  width: 100%
  overflow-x: auto
  margin-top: 1rem
  margin-bottom: 0.5rem
  padding: 0.2rem 0.2rem 0.75rem

table.docutils
  border-radius: 0.2rem
  border-spacing: 0
  border-collapse: collapse

  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05), 0 0 0.0625rem rgba(0, 0, 0, 0.1)

  th
    background: var(--color-background-secondary)

  td,
  th
    // Space things out properly
    padding: 0 0.25rem
    p
      margin: 0.25rem

    // Get the borders looking just-right.
    border-left: 1px solid var(--color-background-border)
    border-right: 1px solid var(--color-background-border)
    border-bottom: 1px solid var(--color-background-border)

    &:first-child
      border-left: none
    &:last-child
      border-right: none
