// Definitely inspired by mkdocs-material.
.admonition
  margin: 1rem auto
  padding: 0 0.5rem 0.5rem 0.5rem

  background: var(--color-admonition-background)

  border-radius: 0.2rem
  border-left: 0.2rem solid var(--color-admonition-title)
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05), 0 0 0.0625rem rgba(0, 0, 0, 0.1)

  font-size: var(--admonition-font-size)

  overflow: hidden
  page-break-inside: avoid

  // First element should have no margin, since the title has it.
  > :nth-child(2)
    margin-top: 0

  // Last item should have no margin, since we'll control that w/ padding
  > :last-child
    margin-bottom: 0


  // Defaults for all admonitions
  p.admonition-title
    position: relative
    margin: 0 -0.5rem 0.5rem
    padding: 0.5rem 0.5rem 0.5rem 2rem

    font-weight: 500
    font-size: var(--admonition-title-font-size)
    background-color: var(--color-admonition-title-background)

    line-height: 1.3

    // Our fancy icon
    &::before
      content: ""
      position: absolute
      left: 0.5rem
      width: 1rem
      height: 1rem
      // color: var(--color-admonition-title)
      background-color: var(--color-admonition-title)

      mask-image: var(--icon-admonition-default)
      mask-repeat: no-repeat

  //
  // Variants
  //
  @each $type, $value in $admonitions
    &.#{$type}
      border-left-color: var(--color-admonition-title--#{$type})
      > .admonition-title
        background-color: var(--color-admonition-title-background--#{$type})
        &::before
          background-color: var(--color-admonition-title--#{$type})
          mask-image: var(--icon-#{nth($value, 2)})

.admonition-todo > .admonition-title
  text-transform: uppercase
