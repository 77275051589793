// This file contains the styles for the contents of the right sidebar, which
// contains the table of contents for the current page.
.toc-title-container
  padding: var(--toc-title-padding)
  padding-top: var(--toc-spacing-vertical)

.toc-title
  color: var(--color-toc-title-text)
  font-size: var(--toc-title-font-size)
  padding-left: var(--toc-spacing-horizontal)
  text-transform: uppercase

// If the ToC is not present, hide these elements coz they're not relevant.
.no-toc
  display: none

.toc-tree-container
  padding-bottom: var(--toc-spacing-vertical)

.toc-tree
  font-size: var(--toc-font-size)
  line-height: 1.3
  border-left: 1px solid var(--color-background-border)

  padding-left: calc(var(--toc-spacing-horizontal) - var(--toc-item-spacing-horizontal))

  // Hide the first "top level" bullet.
  > ul > li:first-child
    padding-top: 0
    & > ul
      padding-left: 0
    & > a
      display: none

  ul
    list-style-type: none
    margin-top: 0
    margin-bottom: 0
    padding-left: var(--toc-item-spacing-horizontal)
  li
    padding-top: var(--toc-item-spacing-vertical)

    &.scroll-current >.reference
      color: var(--color-toc-item-text--active)
      font-weight: bold

  .reference
    color: var(--color-toc-item-text)
    text-decoration: none
    word-break: break-word

.toc-scroll
  max-height: 100vh
  overflow-y: scroll

// Be very annoying when someone includes the table of contents
.contents:not(.this-will-duplicate-information-and-it-is-still-useful-here)
  color: var(--color-problematic)
  background: rgba(255, 0, 0, 0.25)
  &::before
    content: "ERROR: Adding a table of contents in Furo-based documentation is unnecessary, and does not work well with existing styling.Add a 'this-will-duplicate-information-and-it-is-still-useful-here' class, if you want an escape hatch."
