article .sidebar
  float: right
  clear: right
  width: 30%

  margin-left: 1rem
  margin-right: 0

  border-radius: 0.2rem
  background-color: var(--color-background-secondary)
  border: var(--color-background-border) 1px solid

  > *
    padding-left: 1rem
    padding-right: 1rem

  > ul, > ol  // lists need additional padding, because bullets.
    padding-left: 2.2rem

  .sidebar-title
    margin: 0
    padding: 0.5rem 1rem
    border-bottom: var(--color-background-border) 1px solid

    font-weight: 500

// TODO: subtitle
// TODO: dedicated variables?
