// This file serves as the "skeleton" of the theming logic.
//
// This contains the bulk of the logic for handling dark mode, color scheme
// toggling and the handling of color-scheme-specific hiding of elements.

body
  @include fonts
  @include spacing
  @include icons
  @include admonitions
  @include default-admonition(#651fff, "abstract")

  @include colors

.only-light
  display: block !important
.only-dark
  display: none !important

// Enable dark-mode, if requested.
body[data-theme="dark"]
  @include colors-dark

  .only-light
    display: none !important
  .only-dark
    display: block !important

// Enable dark mode, unless explicitly told to avoid.
@media (prefers-color-scheme: dark)
  body:not([data-theme="light"])
    @include colors-dark

    .only-light
      display: none !important
    .only-dark
      display: block !important

//
// Theme toggle presentation
//
body[data-theme="auto"]
  .theme-toggle svg.theme-icon-when-auto
    display: block

body[data-theme="dark"]
  .theme-toggle svg.theme-icon-when-dark
    display: block

body[data-theme="light"]
  .theme-toggle svg.theme-icon-when-light
    display: block
