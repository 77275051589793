@import "normalize.css";
body {
  --font-stack: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  --font-stack--monospace: "SFMono-Regular", Menlo, Consolas, Monaco,
    Liberation Mono, Lucida Console, monospace;
  --font-size--normal: 100%;
  --font-size--small: 87.5%;
  --font-size--small--2: 81.25%;
  --font-size--small--3: 75%;
  --font-size--small--4: 62.5%;
  --sidebar-caption-font-size: var(--font-size--small--2);
  --sidebar-item-font-size: var(--font-size--small);
  --sidebar-search-input-font-size: var(--font-size--small);
  --toc-font-size: var(--font-size--small--3);
  --toc-font-size--mobile: var(--font-size--normal);
  --toc-title-font-size: var(--font-size--small--4);
  --admonition-font-size: 0.8125rem;
  --admonition-title-font-size: 0.8125rem;
  --code-font-size: var(--font-size--small--2);
  --header-height: calc(
    var(--sidebar-item-line-height) + 4 * var(--sidebar-item-spacing-vertical)
  );
  --header-padding: 0.5rem;
  --sidebar-tree-space-above: 1.5rem;
  --sidebar-caption-space-above: 1rem;
  --sidebar-item-line-height: 1rem;
  --sidebar-item-spacing-vertical: 0.5rem;
  --sidebar-item-spacing-horizontal: 1rem;
  --sidebar-item-height: calc(
    var(--sidebar-item-line-height) + 2 *var(--sidebar-item-spacing-vertical)
  );
  --sidebar-expander-width: var(--sidebar-item-height);
  --sidebar-search-space-above: 0.5rem;
  --sidebar-search-input-spacing-vertical: 0.5rem;
  --sidebar-search-input-spacing-horizontal: 0.5rem;
  --sidebar-search-input-height: 1rem;
  --sidebar-search-icon-size: var(--sidebar-search-input-height);
  --toc-title-padding: 0.25rem 0;
  --toc-spacing-vertical: 1.5rem;
  --toc-spacing-horizontal: 1.5rem;
  --toc-item-spacing-vertical: 0.4rem;
  --toc-item-spacing-horizontal: 1rem;
  --icon-search: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><circle cx="10" cy="10" r="7" /><line x1="21" y1="21" x2="15" y2="15" /></svg>');
  --icon-pencil: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83 3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75L3 17.25z"/></svg>');
  --icon-abstract: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 5h16v2H4V5m0 4h16v2H4V9m0 4h16v2H4v-2m0 4h10v2H4v-2z"/></svg>');
  --icon-info: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z"/></svg>');
  --icon-flame: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.55 11.2c-.23-.3-.5-.56-.76-.82-.65-.6-1.4-1.03-2.03-1.66C13.3 7.26 13 4.85 13.91 3c-.91.23-1.75.75-2.45 1.32-2.54 2.08-3.54 5.75-2.34 8.9.04.1.08.2.08.33 0 .22-.15.42-.35.5-.22.1-.46.04-.64-.12a.83.83 0 01-.15-.17c-1.1-1.43-1.28-3.48-.53-5.12C5.89 10 5 12.3 5.14 14.47c.04.5.1 1 .27 1.5.14.6.4 1.2.72 1.73 1.04 1.73 2.87 2.97 4.84 3.22 2.1.27 4.35-.12 5.96-1.6 1.8-1.66 2.45-4.32 1.5-6.6l-.13-.26c-.2-.46-.47-.87-.8-1.25l.05-.01m-3.1 6.3c-.28.24-.73.5-1.08.6-1.1.4-2.2-.16-2.87-.82 1.19-.28 1.89-1.16 2.09-2.05.17-.8-.14-1.46-.27-2.23-.12-.74-.1-1.37.18-2.06.17.38.37.76.6 1.06.76 1 1.95 1.44 2.2 2.8.04.14.06.28.06.43.03.82-.32 1.72-.92 2.27h.01z"/></svg>');
  --icon-question: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.07 11.25l-.9.92C13.45 12.89 13 13.5 13 15h-2v-.5c0-1.11.45-2.11 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41a2 2 0 00-2-2 2 2 0 00-2 2H8a4 4 0 014-4 4 4 0 014 4 3.2 3.2 0 01-.93 2.25M13 19h-2v-2h2M12 2A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10c0-5.53-4.5-10-10-10z"/></svg>');
  --icon-warning: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"/></svg>');
  --icon-failure: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12 6.47 2 12 2m3.59 5L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41 15.59 7z"/></svg>');
  --icon-spark: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.5 20l4.86-9.73H13V4l-5 9.73h3.5V20M12 2c2.75 0 5.1 1 7.05 2.95C21 6.9 22 9.25 22 12s-1 5.1-2.95 7.05C17.1 21 14.75 22 12 22s-5.1-1-7.05-2.95C3 17.1 2 14.75 2 12s1-5.1 2.95-7.05C6.9 3 9.25 2 12 2z"/></svg>');
  --color-admonition-title--caution: #ff9100;
  --color-admonition-title-background--caution: rgba(255, 145, 0, 0.1);
  --color-admonition-title--warning: #ff9100;
  --color-admonition-title-background--warning: rgba(255, 145, 0, 0.1);
  --color-admonition-title--danger: #ff5252;
  --color-admonition-title-background--danger: rgba(255, 82, 82, 0.1);
  --color-admonition-title--attention: #ff5252;
  --color-admonition-title-background--attention: rgba(255, 82, 82, 0.1);
  --color-admonition-title--error: #ff5252;
  --color-admonition-title-background--error: rgba(255, 82, 82, 0.1);
  --color-admonition-title--hint: #00c852;
  --color-admonition-title-background--hint: rgba(0, 200, 82, 0.1);
  --color-admonition-title--tip: #00c852;
  --color-admonition-title-background--tip: rgba(0, 200, 82, 0.1);
  --color-admonition-title--important: #00bfa5;
  --color-admonition-title-background--important: rgba(0, 191, 165, 0.1);
  --color-admonition-title--note: #00b0ff;
  --color-admonition-title-background--note: rgba(0, 176, 255, 0.1);
  --color-admonition-title--seealso: #448aff;
  --color-admonition-title-background--seealso: rgba(68, 138, 255, 0.1);
  --color-admonition-title--admonition-todo: #808080;
  --color-admonition-title-background--admonition-todo: rgba(128, 128, 128, 0.1);
  --color-admonition-title: #651fff;
  --color-admonition-title-background: rgba(101, 31, 255, 0.1);
  --icon-admonition-default: var(--icon-abstract);
  --color-problematic: #b30000;
  --color-foreground-primary: black;
  --color-foreground-secondary: #5a5c63;
  --color-foreground-muted: #72747e;
  --color-foreground-border: #878787;
  --color-background-primary: white;
  --color-background-secondary: #f8f9fb;
  --color-background-hover: #efeff4ff;
  --color-background-hover--transparent: #efeff400;
  --color-background-border: #eeebee;
  --color-announcement-background: #000000dd;
  --color-announcement-text: #eeebee;
  --color-brand-primary: #2962ff;
  --color-brand-content: #2a5adf;
  --color-api-overall: var(--color-foreground-muted);
  --color-api-name: var(--color-brand-content);
  --color-api-pre-name: var(--color-brand-content);
  --color-api-paren: var(--color-foreground-secondary);
  --color-api-keyword: var(--color-problematic);
  --color-api-highlight-on-target: #ffffcc;
  --color-inline-code-background: var(--color-background-secondary);
  --color-highlighted-background: #ddeeff;
  --color-highlighted-text: var(--color-foreground-primary);
  --color-guilabel-background: #ddeeff80;
  --color-guilabel-border: #bedaf580;
  --color-guilabel-text: var(--color-foreground-primary);
  --color-admonition-background: transparent;
  --color-header-background: var(--color-background-primary);
  --color-header-border: var(--color-background-border);
  --color-header-text: var(--color-foreground-primary);
  --color-sidebar-background: var(--color-background-secondary);
  --color-sidebar-background-border: var(--color-background-border);
  --color-sidebar-brand-text: var(--color-foreground-primary);
  --color-sidebar-caption-text: var(--color-foreground-muted);
  --color-sidebar-link-text: var(--color-foreground-secondary);
  --color-sidebar-link-text--top-level: var(--color-brand-primary);
  --color-sidebar-item-background: var(--color-sidebar-background);
  --color-sidebar-item-background--current: var(
    --color-sidebar-item-background
  );
  --color-sidebar-item-background--hover: linear-gradient(
    90deg,
    var(--color-background-hover--transparent) 0%,
    var(--color-background-hover) var(--sidebar-item-spacing-horizontal),
    var(--color-background-hover) 100%
  );
  --color-sidebar-item-expander-background: transparent;
  --color-sidebar-item-expander-background--hover: var(
    --color-background-hover
  );
  --color-sidebar-search-text: var(--color-foreground-primary);
  --color-sidebar-search-background: var(--color-background-secondary);
  --color-sidebar-search-background--focus: var(--color-background-primary);
  --color-sidebar-search-border: var(--color-background-border);
  --color-sidebar-search-icon: var(--color-foreground-muted);
  --color-toc-background: var(--color-background-primary);
  --color-toc-title-text: var(--color-foreground-muted);
  --color-toc-item-text: var(--color-foreground-secondary);
  --color-toc-item-text--hover: var(--color-foreground-primary);
  --color-toc-item-text--active: var(--color-brand-primary);
  --color-content-foreground: var(--color-foreground-primary);
  --color-content-background: transparent;
  --color-link: var(--color-brand-content);
  --color-link--hover: var(--color-brand-content);
  --color-link-underline: var(--color-background-border);
  --color-link-underline--hover: var(--color-foreground-border);
}

.only-light {
  display: block !important;
}

.only-dark {
  display: none !important;
}

body[data-theme=dark] {
  --color-problematic: #e25050;
  --color-foreground-primary: #ffffffd9;
  --color-foreground-secondary: #9ca0a5;
  --color-foreground-muted: #81868d;
  --color-foreground-border: #666666;
  --color-background-primary: #131416;
  --color-background-secondary: #1a1c1e;
  --color-background-hover: #1e2124ff;
  --color-background-hover--transparent: #1e212400;
  --color-background-border: #303335;
  --color-announcement-background: #000000dd;
  --color-announcement-text: #eeebee;
  --color-brand-primary: #2b8cee;
  --color-brand-content: #368ce2;
  --color-highlighted-background: #083563;
  --color-guilabel-background: #08356380;
  --color-guilabel-border: #13395f80;
  --color-api-highlight-on-target: #333300;
  --color-admonition-background: #18181a;
}
body[data-theme=dark] .only-light {
  display: none !important;
}
body[data-theme=dark] .only-dark {
  display: block !important;
}

@media (prefers-color-scheme: dark) {
  body:not([data-theme=light]) {
    --color-problematic: #e25050;
    --color-foreground-primary: #ffffffd9;
    --color-foreground-secondary: #9ca0a5;
    --color-foreground-muted: #81868d;
    --color-foreground-border: #666666;
    --color-background-primary: #131416;
    --color-background-secondary: #1a1c1e;
    --color-background-hover: #1e2124ff;
    --color-background-hover--transparent: #1e212400;
    --color-background-border: #303335;
    --color-announcement-background: #000000dd;
    --color-announcement-text: #eeebee;
    --color-brand-primary: #2b8cee;
    --color-brand-content: #368ce2;
    --color-highlighted-background: #083563;
    --color-guilabel-background: #08356380;
    --color-guilabel-border: #13395f80;
    --color-api-highlight-on-target: #333300;
    --color-admonition-background: #18181a;
  }
  body:not([data-theme=light]) .only-light {
    display: none !important;
  }
  body:not([data-theme=light]) .only-dark {
    display: block !important;
  }
}
body[data-theme=auto] .theme-toggle svg.theme-icon-when-auto {
  display: block;
}

body[data-theme=dark] .theme-toggle svg.theme-icon-when-dark {
  display: block;
}

body[data-theme=light] .theme-toggle svg.theme-icon-when-light {
  display: block;
}

body {
  font-family: var(--font-stack);
}

pre,
code,
kbd,
samp {
  font-family: var(--font-stack--monospace);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article {
  line-height: 1.5;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

h1,
h2 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 300;
}

h3 {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 400;
}

h4,
h5,
h6 {
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

hr.docutils {
  height: 1px;
  padding: 0;
  margin: 2rem 0;
  background-color: var(--color-background-border);
  border: 0;
}

.centered {
  text-align: center;
}

a {
  text-decoration: underline;
  color: var(--color-link);
  text-decoration-color: var(--color-link-underline);
}
a:hover {
  color: var(--color-link--hover);
  text-decoration-color: var(--color-link-underline--hover);
}
a.muted-link {
  text-decoration: none;
}
a.muted-link:hover {
  text-decoration: underline;
  color: var(--color-link);
  text-decoration-color: var(--color-link-underline);
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}

.sidebar-scroll, .toc-scroll, article[role=main] * {
  scrollbar-width: thin;
  scrollbar-color: var(--color-foreground-border) transparent;
}
.sidebar-scroll::-webkit-scrollbar, .toc-scroll::-webkit-scrollbar, article[role=main] *::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}
.sidebar-scroll::-webkit-scrollbar-thumb, .toc-scroll::-webkit-scrollbar-thumb, article[role=main] *::-webkit-scrollbar-thumb {
  background-color: var(--color-foreground-border);
  border-radius: 0.125rem;
}

html,
body {
  height: 100%;
  color: var(--color-foreground-primary);
  background: var(--color-background-primary);
}

article {
  color: var(--color-content-foreground);
  background: var(--color-content-background);
}

.page {
  display: flex;
  min-height: 100%;
}

.mobile-header {
  width: 100%;
  height: var(--header-height);
  background-color: var(--color-header-background);
  color: var(--color-header-text);
  border-bottom: 1px solid var(--color-header-border);
  z-index: 10;
  display: none;
}
.mobile-header.scrolled {
  border-bottom: none;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1), 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
}
.mobile-header .header-center a {
  color: var(--color-header-text);
  text-decoration: none;
}

.main {
  display: flex;
  flex: 1;
}

.sidebar-drawer {
  box-sizing: border-box;
  border-right: 1px solid var(--color-sidebar-background-border);
  background: var(--color-sidebar-background);
  display: flex;
  justify-content: flex-end;
  width: calc((100% - 82em) / 2 + 15em);
  min-width: 15em;
}

.sidebar-container,
.toc-drawer {
  box-sizing: border-box;
  width: 15em;
}

.toc-drawer {
  background: var(--color-toc-background);
  padding-right: 1rem;
}

.sidebar-sticky,
.toc-sticky {
  position: sticky;
  top: 0;
  height: min(100%, 100vh);
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.sidebar-scroll,
.toc-scroll {
  flex-shrink: 1;
  overflow: auto;
  scroll-behavior: smooth;
}

.content {
  padding: 0 3em;
  width: 46em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon {
  display: inline-block;
  height: 1rem;
  width: 1rem;
}
.icon svg {
  width: 100%;
  height: 100%;
}

.announcement {
  background-color: var(--color-announcement-background);
  color: var(--color-announcement-text);
  height: var(--header-height);
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.announcement + .page {
  min-height: calc(100% - var(--header-height));
}

.announcement-content {
  box-sizing: border-box;
  padding: 0.5rem;
  min-width: 100%;
  white-space: nowrap;
  text-align: center;
}

.no-js .theme-toggle-container {
  display: none;
}

.theme-toggle-container {
  vertical-align: middle;
}

.theme-toggle {
  cursor: pointer;
  border: none;
  padding: 0;
  background: transparent;
}

.theme-toggle svg {
  vertical-align: middle;
  height: 1rem;
  width: 1rem;
  color: var(--color-foreground-primary);
  display: none;
}

.theme-toggle-header {
  float: left;
  padding: 1rem 0.5rem;
}

.toc-overlay-icon, .nav-overlay-icon {
  display: none;
  cursor: pointer;
}
.toc-overlay-icon .icon, .nav-overlay-icon .icon {
  color: var(--color-foreground-secondary);
  height: 1.25rem;
  width: 1.25rem;
}

.toc-header-icon, .nav-overlay-icon {
  justify-content: center;
  align-items: center;
}

.toc-content-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.content-icon-container {
  float: right;
  display: flex;
  margin-top: 1.5rem;
  margin-left: 1rem;
  gap: 0.5rem;
}

.sidebar-toggle {
  position: absolute;
  display: none;
}

.sidebar-toggle[name=__toc] {
  left: 20px;
}

.sidebar-toggle:checked {
  left: 40px;
}

.overlay {
  position: fixed;
  top: 0;
  width: 0;
  height: 0;
  transition: width 0ms, height 0ms, opacity 250ms ease-out;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.54);
}

.sidebar-overlay {
  z-index: 20;
}

.toc-overlay {
  z-index: 40;
}

.sidebar-drawer {
  z-index: 30;
  transition: left 250ms ease-in-out;
}

.toc-drawer {
  z-index: 50;
  transition: right 250ms ease-in-out;
}

#__navigation:checked ~ .sidebar-overlay {
  width: 100%;
  height: 100%;
  opacity: 1;
}
#__navigation:checked ~ .page .sidebar-drawer {
  top: 0;
  left: 0;
}

#__toc:checked ~ .toc-overlay {
  width: 100%;
  height: 100%;
  opacity: 1;
}
#__toc:checked ~ .page .toc-drawer {
  top: 0;
  right: 0;
}

@media (min-width: 97em) {
  html {
    font-size: 110%;
  }
}
@media (max-width: 82em) {
  .toc-content-icon {
    display: flex;
  }

  .toc-drawer {
    position: fixed;
    height: 100vh;
    top: 0;
    right: -15em;
    border-left: 1px solid var(--color-background-muted);
  }

  .toc-tree {
    border-left: none;
    font-size: var(--toc-font-size--mobile);
  }

  .sidebar-drawer {
    width: calc((100% - 67em) / 2 + 15em);
  }
}
@media (max-width: 67em) {
  .nav-overlay-icon {
    display: flex;
  }

  .sidebar-drawer {
    position: fixed;
    height: 100vh;
    width: 15em;
    top: 0;
    left: -15em;
  }

  .toc-header-icon {
    display: flex;
  }

  .toc-content-icon, .theme-toggle-content {
    display: none;
  }

  .theme-toggle-header {
    display: block;
  }

  .mobile-header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-header .header-left,
.mobile-header .header-right {
    display: flex;
    height: var(--header-height);
    padding: 0 var(--header-padding);
  }
  .mobile-header .header-left label,
.mobile-header .header-right label {
    height: 100%;
    width: 100%;
  }

  :target {
    scroll-margin-top: var(--header-height);
  }

  .page {
    flex-direction: column;
    justify-content: center;
  }

  .content {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 52em) {
  .content {
    width: 100%;
    overflow-x: auto;
  }
}
@media (max-width: 46em) {
  .content {
    padding: 0 1em;
  }

  article div.sidebar {
    float: none;
    width: 100%;
    margin: 1rem 0;
  }
}
.admonition {
  margin: 1rem auto;
  padding: 0 0.5rem 0.5rem 0.5rem;
  background: var(--color-admonition-background);
  border-radius: 0.2rem;
  border-left: 0.2rem solid var(--color-admonition-title);
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05), 0 0 0.0625rem rgba(0, 0, 0, 0.1);
  font-size: var(--admonition-font-size);
  overflow: hidden;
  page-break-inside: avoid;
}
.admonition > :nth-child(2) {
  margin-top: 0;
}
.admonition > :last-child {
  margin-bottom: 0;
}
.admonition p.admonition-title {
  position: relative;
  margin: 0 -0.5rem 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  font-weight: 500;
  font-size: var(--admonition-title-font-size);
  background-color: var(--color-admonition-title-background);
  line-height: 1.3;
}
.admonition p.admonition-title::before {
  content: "";
  position: absolute;
  left: 0.5rem;
  width: 1rem;
  height: 1rem;
  background-color: var(--color-admonition-title);
  mask-image: var(--icon-admonition-default);
  mask-repeat: no-repeat;
}
.admonition.caution {
  border-left-color: var(--color-admonition-title--caution);
}
.admonition.caution > .admonition-title {
  background-color: var(--color-admonition-title-background--caution);
}
.admonition.caution > .admonition-title::before {
  background-color: var(--color-admonition-title--caution);
  mask-image: var(--icon-spark);
}
.admonition.warning {
  border-left-color: var(--color-admonition-title--warning);
}
.admonition.warning > .admonition-title {
  background-color: var(--color-admonition-title-background--warning);
}
.admonition.warning > .admonition-title::before {
  background-color: var(--color-admonition-title--warning);
  mask-image: var(--icon-warning);
}
.admonition.danger {
  border-left-color: var(--color-admonition-title--danger);
}
.admonition.danger > .admonition-title {
  background-color: var(--color-admonition-title-background--danger);
}
.admonition.danger > .admonition-title::before {
  background-color: var(--color-admonition-title--danger);
  mask-image: var(--icon-spark);
}
.admonition.attention {
  border-left-color: var(--color-admonition-title--attention);
}
.admonition.attention > .admonition-title {
  background-color: var(--color-admonition-title-background--attention);
}
.admonition.attention > .admonition-title::before {
  background-color: var(--color-admonition-title--attention);
  mask-image: var(--icon-warning);
}
.admonition.error {
  border-left-color: var(--color-admonition-title--error);
}
.admonition.error > .admonition-title {
  background-color: var(--color-admonition-title-background--error);
}
.admonition.error > .admonition-title::before {
  background-color: var(--color-admonition-title--error);
  mask-image: var(--icon-failure);
}
.admonition.hint {
  border-left-color: var(--color-admonition-title--hint);
}
.admonition.hint > .admonition-title {
  background-color: var(--color-admonition-title-background--hint);
}
.admonition.hint > .admonition-title::before {
  background-color: var(--color-admonition-title--hint);
  mask-image: var(--icon-question);
}
.admonition.tip {
  border-left-color: var(--color-admonition-title--tip);
}
.admonition.tip > .admonition-title {
  background-color: var(--color-admonition-title-background--tip);
}
.admonition.tip > .admonition-title::before {
  background-color: var(--color-admonition-title--tip);
  mask-image: var(--icon-info);
}
.admonition.important {
  border-left-color: var(--color-admonition-title--important);
}
.admonition.important > .admonition-title {
  background-color: var(--color-admonition-title-background--important);
}
.admonition.important > .admonition-title::before {
  background-color: var(--color-admonition-title--important);
  mask-image: var(--icon-flame);
}
.admonition.note {
  border-left-color: var(--color-admonition-title--note);
}
.admonition.note > .admonition-title {
  background-color: var(--color-admonition-title-background--note);
}
.admonition.note > .admonition-title::before {
  background-color: var(--color-admonition-title--note);
  mask-image: var(--icon-pencil);
}
.admonition.seealso {
  border-left-color: var(--color-admonition-title--seealso);
}
.admonition.seealso > .admonition-title {
  background-color: var(--color-admonition-title-background--seealso);
}
.admonition.seealso > .admonition-title::before {
  background-color: var(--color-admonition-title--seealso);
  mask-image: var(--icon-info);
}
.admonition.admonition-todo {
  border-left-color: var(--color-admonition-title--admonition-todo);
}
.admonition.admonition-todo > .admonition-title {
  background-color: var(--color-admonition-title-background--admonition-todo);
}
.admonition.admonition-todo > .admonition-title::before {
  background-color: var(--color-admonition-title--admonition-todo);
  mask-image: var(--icon-pencil);
}

.admonition-todo > .admonition-title {
  text-transform: uppercase;
}

dl.py dd, dl.cpp dd, dl.c dd, dl.js dd {
  margin-left: 2rem;
}
dl.py dd > :first-child, dl.cpp dd > :first-child, dl.c dd > :first-child, dl.js dd > :first-child {
  margin-top: 0.125rem;
}
dl.py dd > :last-child, dl.cpp dd > :last-child, dl.c dd > :last-child, dl.js dd > :last-child {
  margin-bottom: 1.25rem;
}
dl.py .field-list, dl.cpp .field-list, dl.c .field-list, dl.js .field-list {
  margin-bottom: 0.75rem;
}
dl.py .field-list dt, dl.cpp .field-list dt, dl.c .field-list dt, dl.js .field-list dt {
  text-transform: uppercase;
  font-size: var(--font-size--small);
}
dl.py .field-list dd:empty, dl.cpp .field-list dd:empty, dl.c .field-list dd:empty, dl.js .field-list dd:empty {
  margin-bottom: 0.5rem;
}
dl.py .field-list dd > ul, dl.cpp .field-list dd > ul, dl.c .field-list dd > ul, dl.js .field-list dd > ul {
  margin-left: -1.2rem;
}
dl.py .field-list dd > ul > li > p:nth-child(2), dl.cpp .field-list dd > ul > li > p:nth-child(2), dl.c .field-list dd > ul > li > p:nth-child(2), dl.js .field-list dd > ul > li > p:nth-child(2) {
  margin-top: 0;
}
dl.py .field-list dd > ul > li > p + p:last-child:empty, dl.cpp .field-list dd > ul > li > p + p:last-child:empty, dl.c .field-list dd > ul > li > p + p:last-child:empty, dl.js .field-list dd > ul > li > p + p:last-child:empty {
  margin-top: 0;
  margin-bottom: 0;
}
dl.py > dt, dl.cpp > dt, dl.c > dt, dl.js > dt {
  color: var(--color-api-overall);
}
dl.py > dt:target, dl.cpp > dt:target, dl.c > dt:target, dl.js > dt:target {
  background-color: var(--color-api-highlight-on-target);
}

dl.class > dt,
dl.function > dt,
dl.method > dt,
dl.enum > dt,
dl.enum-class > dt,
dl.enum-struct > dt,
dl.type > dt,
dl.exception > dt {
  padding-left: 3em;
  text-indent: -3em;
}
dl.class > dt .property,
dl.function > dt .property,
dl.method > dt .property,
dl.enum > dt .property,
dl.enum-class > dt .property,
dl.enum-struct > dt .property,
dl.type > dt .property,
dl.exception > dt .property {
  color: var(--color-api-keyword);
  padding-right: 0.25rem;
}
dl.class > dt a.reference.internal .viewcode-link,
dl.function > dt a.reference.internal .viewcode-link,
dl.method > dt a.reference.internal .viewcode-link,
dl.enum > dt a.reference.internal .viewcode-link,
dl.enum-class > dt a.reference.internal .viewcode-link,
dl.enum-struct > dt a.reference.internal .viewcode-link,
dl.type > dt a.reference.internal .viewcode-link,
dl.exception > dt a.reference.internal .viewcode-link {
  width: 3.5rem;
  font-size: var(--font-size--small);
}

.sig-name {
  font-weight: bold;
  color: var(--color-api-name);
}

.sig-prename {
  color: var(--color-api-pre-name);
}

.sig-paren {
  color: var(--color-api-paren);
}

.sig-param {
  font-style: normal;
}

.versionmodified {
  font-style: italic;
}

div.versionadded p, div.versionchanged p, div.deprecated p {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.viewcode-link, .viewcode-back {
  float: right;
  text-align: right;
}

article p.caption,
table > caption,
.code-block-caption {
  font-size: var(--font-size--small);
  text-align: center;
}

.toctree-wrapper.compound .caption, .toctree-wrapper.compound :not(.caption) > .caption-text {
  font-size: var(--font-size--small);
  text-transform: uppercase;
  text-align: initial;
  margin-bottom: 0;
}
.toctree-wrapper.compound > ul {
  margin-top: 0;
  margin-bottom: 0;
}

code.literal {
  background: var(--color-inline-code-background);
  border-radius: 0.2em;
  font-size: var(--font-size--small--2);
  padding: 0.1em 0.2em;
}

article div[class*=" highlight-"],
article div[class^=highlight-] {
  margin: 1em 0;
  display: flex;
}

pre {
  margin: 0;
  padding: 0;
}
article pre {
  line-height: 1.5;
}
pre.literal-block, .highlight pre {
  font-size: var(--code-font-size);
  padding: 0.625rem 0.875rem;
  overflow: auto;
}
pre.literal-block {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
  background-color: var(--color-code-background);
  color: var(--color-code-foreground);
}

.highlight {
  width: 100%;
  border-radius: 0.2rem;
}

.highlighttable {
  width: 100%;
  display: block;
}
.highlighttable tbody {
  display: block;
}
.highlighttable tr {
  display: flex;
}
.highlighttable td.linenos {
  background-color: var(--color-code-background);
  color: var(--color-code-foreground);
  padding: 0.625rem 0.875rem;
  padding-right: 0;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.highlighttable .linenodiv {
  font-size: var(--code-font-size);
  padding-right: 0.5rem;
  box-shadow: -0.0625rem 0 var(--color-code-foreground) inset;
  opacity: 0.5;
}
.highlighttable td.code {
  padding: 0;
  display: block;
  flex: 1;
  overflow: hidden;
}
.highlighttable td.code .highlight {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.footnote-reference {
  font-size: var(--font-size--small--4);
  vertical-align: super;
}

dl.footnote {
  font-size: var(--font-size--small);
  color: var(--color-foreground-secondary);
  display: grid;
  grid-template-columns: max-content auto;
}
dl.footnote dt {
  margin: 0;
}
dl.footnote dt > .fn-backref {
  margin-left: 0.25rem;
}
dl.footnote dt:after {
  content: ":";
}
dl.footnote dt .brackets:before {
  content: "[";
}
dl.footnote dt .brackets:after {
  content: "]";
}

img {
  box-sizing: border-box;
  max-width: 100%;
  height: auto;
}

article figure, article .figure {
  border-radius: 0.2rem;
  margin: 0;
}
article figure :last-child, article .figure :last-child {
  margin-bottom: 0;
}
article .align-left {
  float: left;
  clear: left;
  margin: 0 1rem 1rem;
}
article .align-right {
  float: right;
  clear: right;
  margin: 0 1rem 1rem;
}
article .align-default,
article .align-center {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
article table.align-default {
  display: table;
  text-align: initial;
}

.genindex-jumpbox, .domainindex-jumpbox {
  border-top: 1px solid var(--color-background-border);
  border-bottom: 1px solid var(--color-background-border);
  padding: 0.25rem;
}

.genindex-section h2, .domainindex-section h2 {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}
.genindex-section ul, .domainindex-section ul {
  margin-top: 0;
  margin-bottom: 0;
}

ul,
ol {
  padding-left: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
ul li > p:first-child,
ol li > p:first-child {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
ul li > p:last-child,
ol li > p:last-child {
  margin-top: 0.25rem;
}
ul li > ul,
ul li > ol,
ol li > ul,
ol li > ol {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.simple li > ul,
.simple li > ol,
.toctree-wrapper li > ul,
.toctree-wrapper li > ol {
  margin-top: 0;
  margin-bottom: 0;
}

.field-list dt,
.option-list dt,
dl:not([class]) dt,
dl.simple dt,
dl.footnote dt,
dl.glossary dt {
  font-weight: 500;
  margin-top: 0.25rem;
}
.field-list dt + dt,
.option-list dt + dt,
dl:not([class]) dt + dt,
dl.simple dt + dt,
dl.footnote dt + dt,
dl.glossary dt + dt {
  margin-top: 0;
}
.field-list dt .classifier::before,
.option-list dt .classifier::before,
dl:not([class]) dt .classifier::before,
dl.simple dt .classifier::before,
dl.footnote dt .classifier::before,
dl.glossary dt .classifier::before {
  content: ":";
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.field-list dd > p:first-child,
.field-list dd ul,
.option-list dd > p:first-child,
.option-list dd ul,
dl:not([class]) dd > p:first-child,
dl:not([class]) dd ul,
dl.simple dd > p:first-child,
dl.simple dd ul,
dl.footnote dd > p:first-child,
dl.footnote dd ul,
dl.glossary dd > p:first-child,
dl.glossary dd ul {
  margin-top: 0.125rem;
}
.field-list dd ul,
.option-list dd ul,
dl:not([class]) dd ul,
dl.simple dd ul,
dl.footnote dd ul,
dl.glossary dd ul {
  margin-bottom: 0.125rem;
}

.math-wrapper {
  width: 100%;
  overflow-x: auto;
}

abbr[title] {
  cursor: help;
}

.problematic {
  color: var(--color-problematic);
}

kbd:not(.compound) {
  margin: 0 0.2rem;
  padding: 0 0.2rem;
  border-radius: 0.2rem;
  border: 1px solid var(--color-foreground-border);
  color: var(--color-foreground-primary);
  vertical-align: text-bottom;
  font-size: var(--font-size--small--3);
  display: inline-block;
  box-shadow: 0 0.0625rem 0 rgba(0, 0, 0, 0.2), inset 0 0 0 0.125rem var(--color-background-primary);
  background-color: var(--color-background-secondary);
}

blockquote {
  border-left: 1px solid var(--color-foreground-border);
  font-style: italic;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
}

.reference img {
  vertical-align: middle;
}

h1 > .headerlink,
h2 > .headerlink,
h3 > .headerlink,
h4 > .headerlink,
h5 > .headerlink,
h6 > .headerlink,
dl dt > .headerlink,
p.caption > .headerlink,
table > caption > .headerlink,
.code-block-caption > .headerlink {
  font-weight: 100;
  margin-left: 0.5rem;
  visibility: hidden;
  text-decoration: none;
}
h1:hover > .headerlink,
h2:hover > .headerlink,
h3:hover > .headerlink,
h4:hover > .headerlink,
h5:hover > .headerlink,
h6:hover > .headerlink,
dl dt:hover > .headerlink,
p.caption:hover > .headerlink,
table > caption:hover > .headerlink,
.code-block-caption:hover > .headerlink {
  visibility: visible;
}

:target > h1:first-child > .headerlink,
:target > h2:first-child > .headerlink,
:target > h3:first-child > .headerlink,
:target > h4:first-child > .headerlink,
:target > h5:first-child > .headerlink,
:target > h6:first-child > .headerlink {
  visibility: visible;
}

p.rubric {
  text-transform: uppercase;
  font-size: var(--font-size--small);
}

article .sidebar {
  float: right;
  clear: right;
  width: 30%;
  margin-left: 1rem;
  margin-right: 0;
  border-radius: 0.2rem;
  background-color: var(--color-background-secondary);
  border: var(--color-background-border) 1px solid;
}
article .sidebar > * {
  padding-left: 1rem;
  padding-right: 1rem;
}
article .sidebar > ul, article .sidebar > ol {
  padding-left: 2.2rem;
}
article .sidebar .sidebar-title {
  margin: 0;
  padding: 0.5rem 1rem;
  border-bottom: var(--color-background-border) 1px solid;
  font-weight: 500;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.2rem 0.2rem 0.75rem;
}

table.docutils {
  border-radius: 0.2rem;
  border-spacing: 0;
  border-collapse: collapse;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05), 0 0 0.0625rem rgba(0, 0, 0, 0.1);
}
table.docutils th {
  background: var(--color-background-secondary);
}
table.docutils td,
table.docutils th {
  padding: 0 0.25rem;
  border-left: 1px solid var(--color-background-border);
  border-right: 1px solid var(--color-background-border);
  border-bottom: 1px solid var(--color-background-border);
}
table.docutils td p,
table.docutils th p {
  margin: 0.25rem;
}
table.docutils td:first-child,
table.docutils th:first-child {
  border-left: none;
}
table.docutils td:last-child,
table.docutils th:last-child {
  border-right: none;
}

.guilabel {
  background-color: var(--color-guilabel-background);
  border: 1px solid var(--color-guilabel-border);
  color: var(--color-guilabel-text);
  padding: 0 0.3em;
  border-radius: 0.5em;
  font-size: 0.9em;
}

footer {
  font-size: var(--font-size--small);
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.related-information {
  margin-top: 1rem;
  padding: 0.75rem;
  padding-bottom: 1rem;
  border-top: 1px solid var(--color-background-border);
  line-height: 1.5;
  color: var(--color-foreground-secondary);
}

.related-pages a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.related-pages a:hover .page-info .title {
  text-decoration: underline;
  color: var(--color-link);
  text-decoration-color: var(--color-link-underline);
}
.related-pages a svg,
.related-pages a svg > use {
  flex-shrink: 0;
  color: var(--color-foreground-border);
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 0.5rem;
}
.related-pages a.next-page {
  max-width: 50%;
  float: right;
  clear: right;
  text-align: right;
}
.related-pages a.prev-page {
  max-width: 50%;
  float: left;
  clear: left;
}
.related-pages a.prev-page svg {
  transform: rotate(180deg);
}

.page-info {
  display: flex;
  flex-direction: column;
}
.next-page .page-info {
  align-items: flex-end;
}
.page-info .context {
  display: flex;
  align-items: center;
  padding-bottom: 0.1rem;
  color: var(--color-foreground-muted);
  font-size: var(--font-size--small);
  text-decoration: none;
}

ul.search {
  padding-left: 0;
  list-style: none;
}
ul.search li {
  padding: 1rem 0;
  border-bottom: 1px solid var(--color-background-border);
}

[role=main] .highlighted {
  background-color: var(--color-highlighted-background);
  color: var(--color-highlighted-text);
}

.sidebar-brand {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: var(--sidebar-item-spacing-vertical) var(--sidebar-item-spacing-horizontal);
  text-decoration: none;
}

.sidebar-brand-text {
  color: var(--color-sidebar-brand-text);
  overflow-wrap: break-word;
  margin: var(--sidebar-item-spacing-vertical) 0;
  font-size: 1.5rem;
}

.sidebar-logo-container {
  margin: var(--sidebar-item-spacing-vertical) 0;
}

.sidebar-logo {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.sidebar-search-container {
  display: flex;
  align-items: center;
  margin-top: var(--sidebar-search-space-above);
  position: relative;
  background: var(--color-sidebar-search-background);
}
.sidebar-search-container:hover, .sidebar-search-container:focus-within {
  background: var(--color-sidebar-search-background--focus);
}
.sidebar-search-container::before {
  content: "";
  position: absolute;
  left: var(--sidebar-item-spacing-horizontal);
  width: var(--sidebar-search-icon-size);
  height: var(--sidebar-search-icon-size);
  background-color: var(--color-sidebar-search-icon);
  mask-image: var(--icon-search);
}

.sidebar-search {
  box-sizing: border-box;
  border: none;
  border-top: 1px solid var(--color-sidebar-search-border);
  border-bottom: 1px solid var(--color-sidebar-search-border);
  padding-top: var(--sidebar-search-input-spacing-vertical);
  padding-bottom: var(--sidebar-search-input-spacing-vertical);
  padding-right: var(--sidebar-search-input-spacing-horizontal);
  padding-left: calc(var(--sidebar-item-spacing-horizontal) + var(--sidebar-search-input-spacing-horizontal) + var(--sidebar-search-icon-size));
  width: 100%;
  color: var(--color-sidebar-search-foreground);
  background: transparent;
  z-index: 10;
}
.sidebar-search:focus {
  outline: none;
}
.sidebar-search::placeholder {
  font-size: var(--sidebar-search-input-font-size);
}

#searchbox .highlight-link {
  padding: var(--sidebar-item-spacing-vertical) var(--sidebar-item-spacing-horizontal) 0;
  margin: 0;
  text-align: center;
}
#searchbox .highlight-link a {
  color: var(--color-sidebar-search-icon);
  font-size: var(--font-size--small--2);
}

.sidebar-tree {
  font-size: var(--sidebar-item-font-size);
  margin-top: var(--sidebar-tree-space-above);
  margin-bottom: var(--sidebar-item-spacing-vertical);
}
.sidebar-tree ul {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
}
.sidebar-tree li {
  position: relative;
  margin: 0;
}
.sidebar-tree li > ul {
  margin-left: var(--sidebar-item-spacing-horizontal);
}
.sidebar-tree .icon {
  color: var(--color-sidebar-link-text);
}
.sidebar-tree .reference {
  box-sizing: border-box;
  color: var(--color-sidebar-link-text);
  display: inline-block;
  line-height: var(--sidebar-item-line-height);
  text-decoration: none;
  height: 100%;
  width: 100%;
  padding: var(--sidebar-item-spacing-vertical) var(--sidebar-item-spacing-horizontal);
}
.sidebar-tree .reference:hover {
  background: var(--color-sidebar-item-background--hover);
}
.sidebar-tree .reference.external::after {
  content: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="%23607D8B" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" /><line x1="10" y1="14" x2="20" y2="4" /><polyline points="15 4 20 4 20 9" /></svg>');
  margin: 0 0.25rem;
  vertical-align: middle;
  color: var(--color-sidebar-link-text);
}
.sidebar-tree .current-page > .reference {
  font-weight: bold;
}
.sidebar-tree label {
  position: absolute;
  top: 0;
  right: 0;
  height: var(--sidebar-item-height);
  width: var(--sidebar-expander-width);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-tree .caption, .sidebar-tree :not(.caption) > .caption-text {
  font-size: var(--sidebar-caption-font-size);
  color: var(--color-sidebar-caption-text);
  font-weight: bold;
  text-transform: uppercase;
  margin: var(--sidebar-caption-space-above) 0 0 0;
  padding: var(--sidebar-item-spacing-vertical) var(--sidebar-item-spacing-horizontal);
}
.sidebar-tree li.has-children > .reference {
  padding-right: var(--sidebar-expander-width);
}
.sidebar-tree .toctree-l1 > .reference, .sidebar-tree .toctree-l1 > label .icon {
  color: var(--color-sidebar-link-text--top-level);
}
.sidebar-tree label {
  background: var(--color-sidebar-item-expander-background);
}
.sidebar-tree label:hover {
  background: var(--color-sidebar-item-expander-background--hover);
}
.sidebar-tree .current > .reference {
  background: var(--color-sidebar-item-background--current);
}
.sidebar-tree .current > .reference:hover {
  background: var(--color-sidebar-item-background--hover);
}

.toctree-checkbox {
  position: absolute;
  display: none;
}

.toctree-checkbox ~ ul {
  display: none;
}
.toctree-checkbox ~ label .icon svg {
  transform: rotate(90deg);
}

.toctree-checkbox:checked ~ ul {
  display: block;
}
.toctree-checkbox:checked ~ label .icon svg {
  transform: rotate(-90deg);
}

.toc-title-container {
  padding: var(--toc-title-padding);
  padding-top: var(--toc-spacing-vertical);
}

.toc-title {
  color: var(--color-toc-title-text);
  font-size: var(--toc-title-font-size);
  padding-left: var(--toc-spacing-horizontal);
  text-transform: uppercase;
}

.no-toc {
  display: none;
}

.toc-tree-container {
  padding-bottom: var(--toc-spacing-vertical);
}

.toc-tree {
  font-size: var(--toc-font-size);
  line-height: 1.3;
  border-left: 1px solid var(--color-background-border);
  padding-left: calc(var(--toc-spacing-horizontal) - var(--toc-item-spacing-horizontal));
}
.toc-tree > ul > li:first-child {
  padding-top: 0;
}
.toc-tree > ul > li:first-child > ul {
  padding-left: 0;
}
.toc-tree > ul > li:first-child > a {
  display: none;
}
.toc-tree ul {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: var(--toc-item-spacing-horizontal);
}
.toc-tree li {
  padding-top: var(--toc-item-spacing-vertical);
}
.toc-tree li.scroll-current > .reference {
  color: var(--color-toc-item-text--active);
  font-weight: bold;
}
.toc-tree .reference {
  color: var(--color-toc-item-text);
  text-decoration: none;
  word-break: break-word;
}

.toc-scroll {
  max-height: 100vh;
  overflow-y: scroll;
}

.contents:not(.this-will-duplicate-information-and-it-is-still-useful-here) {
  color: var(--color-problematic);
  background: rgba(255, 0, 0, 0.25);
}
.contents:not(.this-will-duplicate-information-and-it-is-still-useful-here)::before {
  content: "ERROR: Adding a table of contents in Furo-based documentation is unnecessary, and does not work well with existing styling.Add a 'this-will-duplicate-information-and-it-is-still-useful-here' class, if you want an escape hatch.";
}