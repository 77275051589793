ul,
ol
  padding-left: 1.2rem

  // Space lists out like paragraphs
  margin-top: 1rem
  margin-bottom: 1rem
  // reduce margins within li.
  li
    > p:first-child
      margin-top: 0.25rem
      margin-bottom: 0.25rem

    > p:last-child
      margin-top: 0.25rem

    > ul,
    > ol
      margin-top: 0.5rem
      margin-bottom: 0.5rem

// Don't space lists out when they're "simple" or in a `.. toctree::`
.simple,
.toctree-wrapper
  li
    > ul,
    > ol
      margin-top: 0
      margin-bottom: 0

// Definition Lists
.field-list,
.option-list,
dl:not([class]),
dl.simple,
dl.footnote,
dl.glossary
  dt
    font-weight: 500
    margin-top: 0.25rem
    + dt
      margin-top: 0

    .classifier::before
      content: ":"
      margin-left: 0.2rem
      margin-right: 0.2rem

  dd
    > p:first-child,
    ul
      margin-top: 0.125rem

    ul
      margin-bottom: 0.125rem
