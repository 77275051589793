.genindex-jumpbox, .domainindex-jumpbox
  border-top: 1px solid var(--color-background-border)
  border-bottom: 1px solid var(--color-background-border)
  padding: 0.25rem

.genindex-section, .domainindex-section
  h2
    margin-top: 0.75rem
    margin-bottom: 0.5rem
  ul
    margin-top: 0
    margin-bottom: 0
