// This file stylizes the API documentation (stuff generated by autodoc). It's
// deeply nested due to how autodoc structures the HTML without enough classes
// to select the relevant items.

// API docs!
dl.py, dl.cpp, dl.c, dl.js
  // Tweak the spacing of all the things!
  dd
    margin-left: 2rem
    > :first-child
      margin-top: 0.125rem
    > :last-child
      margin-bottom: 1.25rem

  // This is used for the arguments
  .field-list
    margin-bottom: 0.75rem

    // "Headings" (like "Parameters" and "Return")
    dt
      text-transform: uppercase
      font-size: var(--font-size--small)

    dd:empty
      margin-bottom: 0.5rem
    dd > ul
      margin-left: -1.2rem
      > li
        > p:nth-child(2)
          margin-top: 0
        // When the last-empty-paragraph follows a paragraph, it doesn't need
        // to augument the existing spacing.
        > p + p:last-child:empty
          margin-top: 0
          margin-bottom: 0

  // Colorize the elements
  > dt
    color: var(--color-api-overall)
    &:target
      background-color: var(--color-api-highlight-on-target)

dl.class,
dl.function,
dl.method,
dl.enum,
dl.enum-class,
dl.enum-struct,
dl.type,
dl.exception
  > dt
    padding-left: 3em
    text-indent: -3em
    .property
      color: var(--color-api-keyword)
      padding-right: 0.25rem

    // adjust the size of the [source] link on the right.
    a.reference.internal
      .viewcode-link
        width: 3.5rem
        font-size: var(--font-size--small)

.sig-name
  font-weight: bold
  color: var(--color-api-name)
.sig-prename
  color: var(--color-api-pre-name)
.sig-paren
  color: var(--color-api-paren)
.sig-param
  font-style: normal

.versionmodified
  font-style: italic
div.versionadded, div.versionchanged, div.deprecated
  p
    margin-top: 0.125rem
    margin-bottom: 0.125rem

// Align the [docs] and [source] to the right.
.viewcode-link, .viewcode-back
  float: right
  text-align: right
